import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const endpoint = `${process.env.REACT_APP_TOKEN_ENDPOINT}` || '/token';

export function getPasscode() {
  const match = window.location.search.match(/passcode=(.*)&?/);
  console.log('match', match);
  const params = new URLSearchParams(window.location.search);
  // const passCode = params.get('passcode');
  // const name = params.get('name')||window.sessionStorage.getItem('name');
  const CID = params.get('CID') || window.sessionStorage.getItem('roomName');
  const role = params.get('role') || window.sessionStorage.getItem('role');
  // const passcode = passCode ? passCode : window.sessionStorage.getItem('passcode');
  return { role, CID };
}

export function fetchToken(
  name: string,
  room: string,
  passcode: string,
  create_room = true,
  create_conversation = process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true'
) {
  return fetch(endpoint + '/token', {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      'x-api-key': process.env.REACT_APP_ENDPOINT_KEY || '',
    },
    body: JSON.stringify({
      role: process.env.REACT_APP_ROLE || 2,
      customerId: room,
      room_name: room,
      passcode,
      create_room,
      create_conversation,
    }),
  });
}

export function verifyPasscode(passcode: string) {
  return fetchToken('temp-name', 'temp-room', passcode, false /* create_room */, false /* create_conversation */).then(
    async res => {
      const jsonResponse = await res.json();
      if (res.status === 401) {
        return { isValid: false, error: jsonResponse.error?.message };
      }

      if (res.ok && jsonResponse.token) {
        return { isValid: true };
      }
    }
  );
}

export function getErrorMessage(message: string) {
  switch (message) {
    case 'passcode incorrect':
      return 'Passcode is incorrect';
    case 'passcode expired':
      return 'Passcode has expired';
    default:
      return message;
  }
}

export default function usePasscodeAuth() {
  const history = useHistory();

  const [user, setUser] = useState<{ displayName: undefined; photoURL: undefined; passcode: string } | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);

  const getToken = useCallback(
    (name: string, room: string) => {
      return fetchToken(name, room, user!.passcode)
        .then(async res => {
          // console.log(res);
          if (res.ok) {
            return res;
          }
          const json = await res.json();
          const errorMessage = getErrorMessage(json.error?.message || res.statusText);
          throw Error(errorMessage);
        })
        .then(async res => {
          let data = await res.json();
          if (data.status) {
            return data;
          } else {
            throw Error(data.message);
          }
        });
    },
    [user]
  );

  const updateRecordingRules = useCallback(
    async (room_sid, rules) => {
      return fetch('/recordingrules', {
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ room_sid, rules, passcode: user?.passcode }),
        method: 'POST',
      }).then(async res => {
        const jsonResponse = await res.json();
        if (!res.ok) {
          const error = new Error(jsonResponse.error?.message || 'There was an error updating recording rules');
          error.code = jsonResponse.error?.code;

          return Promise.reject(error);
        }

        return jsonResponse;
      });
    },
    [user]
  );

  const updateSupervisorRules = useCallback(
    async (room_sid, videoParticipants) => {
      console.log(videoParticipants, room_sid, user);
      //@ts-ignore
      if (videoParticipants && videoParticipants.length > 0 && user && user.role == 3) {
        videoParticipants.forEach((vp: any) => {
          const rules = [
            { type: 'include', all: true },
            { type: 'exclude', publisher: 'Manager' },
          ];
          return fetch(endpoint + '/managerRules', {
            headers: {
              'Content-Type': 'application/json',
              'x-api-key': process.env.REACT_APP_ENDPOINT_KEY || '',
            },
            body: JSON.stringify({ room_sid, rules, identity: vp }),
            method: 'POST',
          }).then(async res => {
            const jsonResponse = await res.json();
            if (!res.ok) {
              const error = new Error(jsonResponse.error?.message || 'There was an error updating manager rules');
              error.code = jsonResponse.error?.code;

              return Promise.reject(error);
            }

            return jsonResponse;
          });
        });
      }
      return Promise.resolve();
    },
    [user]
  );

  useEffect(() => {
    const { CID, role } = getPasscode();

    // if (passcode) {
    //   verifyPasscode(passcode)
    //     .then(verification => {
    //       if (verification?.isValid) {
    //         setUser({ passcode, displayName:name, roomName: CID } as any);
    //         window.sessionStorage.setItem('passcode', passcode);
    //         window.sessionStorage.setItem('name', name||'');
    //         window.sessionStorage.setItem('roomName', CID||'');
    //         setTimeout(() => {
    //           history.replace(window.location.pathname);
    //         },100);

    //         // history.push(`${window.location.pathname}/room/${CID}/${name}`);
    //       }
    //     })
    //     .then(() => setIsAuthReady(true));
    // } else {
    // window.sessionStorage.setItem('name', name||'Default Name');
    window.sessionStorage.setItem('roomName', CID || '');
    window.sessionStorage.setItem('role', role || '2');
    window.sessionStorage.setItem('passcode', '12345');
    setUser({ passcode: '123456', roomName: CID || null, role } as any);
    setIsAuthReady(true);
    // }
  }, [history]);

  const signIn = useCallback((passcode: string) => {
    return verifyPasscode(passcode).then(verification => {
      if (verification?.isValid) {
        setUser({ passcode } as any);
        window.sessionStorage.setItem('passcode', passcode);
      } else {
        throw new Error(getErrorMessage(verification?.error));
      }
    });
  }, []);

  const signOut = useCallback(() => {
    setUser(null);
    window.sessionStorage.removeItem('passcode');
    window.sessionStorage.removeItem('roomName');
    window.sessionStorage.removeItem('name');
    return Promise.resolve();
  }, []);

  return { user, isAuthReady, getToken, signIn, signOut, updateRecordingRules, updateSupervisorRules };
}
